.accueil_container {
  min-height: calc(100vh - 189px);
  padding: 10px;
  /* padding-top: 50px; */
  padding-bottom: 40px;
  /* padding-bottom: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,0.2) 50%, rgba(255,255,255,1) 100%); */
}
.accueil_text {
  max-width: 800px;
  font-family: apex;
  padding: 20px;
  padding-bottom: 0px;
  font-size: 18px;
  margin-bottom: 70px;
  text-align: center;
}
.cta_btn {
  font-size: 18px;
  text-align: center;
  font-weight: 100;
  border: solid 1px #016EBB;
  background-color: #016EBB;
  color: white !important;
  border-radius: 50px;
  max-width: 90%;
  padding: 10px 30px 10px 30px;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(24 144 255 / 60%);
  transition: box-shadow 0.15s ease-out, transform 0.15s ease-out;
}
.cta_btn:hover {
  color: white !important;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 34%),
    0 7px 10px -5px rgb(24 144 255 / 90%);
  transform: translateY(-3px);
}
.barsMenu {
  margin-right: 10px !important;
}

@media (max-width: 767px) {
  .accueil_text {
    font-size: 16px;
    padding: 0 20px;

  }
  .cta_btn {
    font-size: 4x;
    max-width: 93%;
    padding: 10px 20px 10px 20px;
  }
}
@media (max-width: 460px) {
  .accueil_text {
    /* text-align: left; */
    font-size: 16px;
  }
  .cta_btn {
    font-size: 14px;
  }
  .accueil_container {
    padding-top: 0px;
  }
}
@media (max-width: 360px) {
  .accueil_container {
    padding-top: 30px;
  }
  .accueil_text {
    font-size: 14px;
  }
  .cta_btn {
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    max-width: 96%;
  }
}
