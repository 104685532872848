@import "~antd/dist/antd.css";
@font-face {
  font-family: "apex";
  src: local("apex"), url("./fonts/Poppins.ttf") format("truetype");
}
/* @font-face {
  font-family: "apex_bold";
  src: local("apex"), url("./fonts/apex_bold.otf") format("opentype");
} */
* {
  font-family: apex;
}

.menuBar {
  padding: 0 10px;
  width: 100%;
  max-width: 100vw;
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
  box-shadow: 0 0 30px #f3f1f1;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.logo {
  position: absolute;
  left: 0px;
  z-index: 100;
  margin-left: 30px;
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.2 ease-out;
  /* flex: 0 */
}
.logo img {
  height: 65px;
  width: auto;
}
.logo a {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}
.logo.hide {
  display: none;
}
.menuCon {
  /* width: calc(100% - 150px); */
  width: 100%;
  max-width: 100vw;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.menuCon .ant-btn-primary {
  background-color: white !important;
}
.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .rightMenu {
  float: right;
}
.menuCon .rightMenu ul {
  text-align: right;
  justify-content: flex-end !important;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu > span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item,
.ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}
.rightMenu ul {
  text-align: right !important;
}
/* .connected_as {
  margin-right: 8px;
} */
/* @media (max-width: 926px) {
  .logo {
    opacity: 0;
  }
} */
@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }
  .leftMenu,
  .rightMenu {
    display: none;
  }
  .logo {
    opacity: 1;
    left: 20px;
    margin-left: 20px;
  }
  .logo a {
    margin-left: -50px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }
}
@media (max-width: 350px) {
}
