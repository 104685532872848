.event_details_container {
  min-height: calc(100vh - 189px);
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 40px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgb(255, 255, 255);
  /* background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 203, 31, 0.2) 50%,
    rgba(255, 255, 255, 1) 100%
  ) !important; */
}
.event_details_layout {
  width: 100%;
  max-width: 800px;
  border: 1px solid #f0f0f0 !important;
  box-shadow: rgb(149 157 165 / 15%) 0px 8px 24px !important;
}
.ant-layout-sider {
  background: white !important;
  width: 50% !important;
  min-width: 50% !important;
  max-width: auto !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border-right: 1px solid #f0f0f0 !important;
  padding: 15px;
  font-weight: 800;
}
.ant-layout-sider-children {
  width: 100% !important;
}
.ant-layout-header {
  background: white !important;
  height: auto !important;
  padding: 0 !important;
}
.ant-layout-content {
  background: white !important;
  background-color: white !important;
  padding: 15px;
}
.ant-layout-footer {
  background: white !important;
  background-color: white !important;
}
.details_titre {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}
.event_details_cover_image {
  width: 100%;
  max-height: 300px;
}
.contenu_titre {
  width: 100%;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold !important;
}
.sessions_btns_container {
  width: 100% !important;
  flex-direction: column !important;
}
.sessions_btns_container button,
.sessions_btns_container div {
  width: 100% !important;
}
.sessions_btns_container button {
  max-width: 350px;
}
.infos_pratiques_titre {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}
.mail_contact_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.detail_contact_text {
  font-size: 14px;
}
.detail_contact_row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  width: 100%;
  margin-bottom: 15px;
}
.detail_icon_container {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.event_details_layout .ant-btn-primary {
  border: solid 1px #016ebb !important;
  background-color: #016ebb !important;
}

@media (max-width: 767px) {
  .ant-layout {
    flex-direction: column !important;
  }
  section,
  .ant-layout-sider,
  aside {
    width: 100% !important;
    max-width: 100% !important;
  }
  .contact_text {
    font-size: 14px;
  }
  .ant-space-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
