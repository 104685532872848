.footer_container {
  /* position: fixed; */
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  /* background: rgba(255,203,31,1); */

}
.footer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 800px;
}
.footer img {
  width: 100%;
}
.footer_img_container {
  width: 20%;
  padding: 10px;
}
