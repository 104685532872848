.add_new_event_container {
  min-height: calc(100vh - 189px);
  padding: 10px;
  padding-top: 50px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-width: 80%;
  /* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,0.2) 50%, rgba(255,255,255,1) 100%); */

}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
#dynamic_form_item {
  width: 100%;
  max-width: 700px;
}
.edit_event_en_tete {
  margin-bottom: 40px !important;
}
.image_picker .ant-radio-button-wrapper {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 120px !important;
  height: 90px !important;
  padding: 5px 5px 5px 5px !important;
}
.image_picker .ant-radio-button-wrapper img {
  /* width: 100px !important; */
  width: 130px !important;
  height: 80px !important;
}
.image_picker .ant-radio-button-wrapper span {
  /* width: 100px !important; */
  /* height: 100px !important; */
}
.image_picker {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-wrap: wrap;
}
.image_picker label {
  flex: 1 0 40%;
  margin: 5px;
  max-width: 140px;
}
.photo_type_choice {
  margin-bottom: 10px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}