.login_container {
  min-height: calc(100vh - 189px);
  padding: 40px;
    padding-top: 30px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,0.2) 50%, rgba(255,255,255,1) 100%); */

  }
  .ant-input-prefix {
    margin-right: 10px !important;
  }
  .login-form-button {
    width: 100%;
  }