.infos_container {
  min-height: calc(100vh - 189px);
  padding-bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,0.2) 50%, rgba(255,255,255,1) 100%); */

}
.map_container {
  width: 90%;
  max-width: 800px;
  height: 400px;
  border: solid 1px #016EBB;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(24 144 255 / 40%);
  margin-bottom: 60px;
}
.contact_en_tete {
  font-size: 20px;
  margin-bottom: 40px;
  position: relative;
  padding-bottom: 35px;
  text-align: center;
  /* font-weight: 800; */
}
.contact_en_tete::after {
  content: "";
  width: 50%;
  height: 1px;
  position: absolute;
  background-color: #016EBB;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.contact_footer {
  font-size: 20px;
  margin-top: 50px;
  max-width: 800px;

  text-align: center;
}
.contact_infos_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 0 15px;
}
.contact_row {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 100%;
  margin-bottom: 15px;
}
.icon_container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* border: solid 2px #016EBB; */
  /* background-color: #FFCB1F;; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  color: #016EBB !important
}
 a {
   color: #016EBB !important
 }
.contact_text {
  width: calc(100vw - 60px);
  max-width: 300px;
}
.last_text {
  font-size: 18px;
  margin-bottom: 60px;
  padding: 0 20px;
  max-width: 800px;
  text-align: center;
}
@media (max-width: 767px) {
  .contact_en_tete {
    font-size: 18px;
  }
  .contact_row {
    font-size: 18px;
  }
  .contact_footer {
    font-size: 18px;
  }
  .contact_text {
    font-size: 16px;
  }
}
@media (max-width: 350px) {
  .contact_en_tete {
    font-size: 16px;
  }
  .contact_row {
    font-size: 16px;
  }
  .contact_footer {
    font-size: 16px;
  }
  .contact_text {
    font-size: 14px !important;
  }
}
