* {
  box-sizing: border-box;
}
html {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
