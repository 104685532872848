.agenda_container {
  min-height: calc(100vh - 189px);
  padding: 10px;
  padding-top: 50px;
  padding-bottom: 40px;
  width: 100%;
  /* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,0.2) 50%, rgba(255,255,255,1) 100%); */

  
}
.event_cards_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  
}
.ant-skeleton-image {
  width: 250px !important;
  height: 180px !important;
}
.event_card {
  width: 300px;
  border: 1px solid rgba(160, 159, 159, 0.5) !important;
  border-radius: 4px !important;
  position: relative !important;
  margin: 15px !important;
  box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px !important;
  transition: transform 0.15s ease-out, box-shadow 0.15s ease-out !important;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: column;
  background: rgb(255,255,255);
  /* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,1) 10%, rgba(255,255,255,1) 70%) !important; */

}
.ant-card-meta-title {
  white-space: pre-wrap !important;
}
.ant-card-actions {
  margin-top: auto !important;
}
.event_card:hover {
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px !important;
  transform: translateY(-2px);
}
.event_card .ant-card-meta-description {
  transition: color 0.3s ease-out, font-size 0.3s ease-out;
 
}
.event_card:hover .ant-card-meta-description {
  /* color: black !important;
  font-weight: bold !important; */

}

.ant-card-cover {
  width: 300px !important;
  max-height: 180px !important;
  overflow: hidden;
}
.cover_image {
  max-height: 250px !important;
  width: 100% !important;
  position: relative;
  /* z-index: 1; */
}
.ant-card-cover:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0px;
  left: 0px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  z-index: 2;
}
.date_chip {
  font-family: apex_bold;
  position: absolute;
  z-index: 100;
  top: 14px;
  left: 14px;
  font-size: 14px;
  padding: 5px 15px 5px 15px;
  background-color: rgba(24, 143, 255, 0.3);
  color: white;
  border-radius: 4px;
  border: 1px solid rgba(24, 143, 255, 1) !important;
  text-align: center;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 30%),
    0 7px 10px -5px rgb(24 144 255 / 80%);
}
.add_event_btn {
  width: 60px;
  height: 60px;
  align-self: center !important;
  background-color: #1890ff;
  border-radius: 50%;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin: 30px;
  cursor: pointer;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%),
    0 7px 10px -5px rgb(24 144 255 / 60%);
  transition: box-shadow 0.15s ease-out, transform 0.15s ease-out;
}
.add_event_btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 34%),
    0 7px 10px -5px rgb(24 144 255 / 90%);
}
.ant-card-meta-description {
  /* font-family: apex_bold; */
  /* color: #1890ff !important; */
  /* font-weight: bold !important; */
}

.session_nb_inscrits{
  /* border-radius: 5px !important; */
  margin-top: 20px !important;
  padding: 4px 10px !important;
  font-size: 12px !important;
}
.ant-tag-blue {
background: rgba(255,203,31,0.15) !important;
color: #016EBB !important;
border-color: rgba(1, 109, 187, 0.5) !important;
}
