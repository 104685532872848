.inscription_container {
  min-height: calc(100vh - 189px);
  /* max-height: calc(100vh - 169px); */
  padding: 10px;
  padding-top: 50px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-width: 80%;
  /* overflow-x: auto !important; */
  /* background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,0.2) 50%, rgba(255,255,255,1) 100%); */

}
.forms {
  width: 100%;
 max-width: 1500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nom_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nom_container span {
  margin-right: 20px;
  font-weight: bold;
  font-size: 16px;
}
input.required {
  border: 1px solid red !important;
}
.deja_inscrit_btn_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inscription_header {
  font-size: 18px;
  /* font-weight: bold; */
  margin-bottom: 40px;
  text-align: center;
}
.atelier_titre {
  font-weight: bolder !important;
}
.atelier_date{
  color: rgb(75, 74, 74);
  font-size: 16px;
  font-style: italic;
}
.ant-input[disabled] {
  color: black !important;
  border: none !important;
  background: transparent !important;
}
.delete_inscription_btn {
  cursor: pointer;
}
.ant-table table {text-align: center !important;}
/* @media (max-width: 767px) {
  .forms {
    padding: 0 10px;
  }
}
@media (max-width: 450px) {
  .forms {
    padding: 0 6px !important;
  }
} */
