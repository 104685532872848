.about_synergie_container {
  min-height: calc(100vh - 189px);
  padding: 10px;
  /* padding-top: 50px; */
  padding-bottom: 40px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,203,31,0.2) 50%, rgba(255,255,255,1) 100%); */

}
.synergie_logo {
  max-width: 250px;
  width: 80%;
  margin-top: 40px;
}
.about_subtitle {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 80px;
  padding: 0 20px;
}
.centered {
  text-align: center;
}
.about_text {
  font-size: 18px;
  padding: 0 20px;
  max-width: 800px;
}
.about_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1000px;

}
.about_card {
  border: 1px solid #f0f0f0;
  display: flex;
  justify-content: flex-start;
  padding-top: 60px;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 300px;
  height: 340px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin: 15px;
  margin-bottom: 30px;
  background: white !important;
}
.about_card img {
  height: 100px;
}
.about_card .number_container {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  border: 1px solid #f0f0f0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 22px;
  font-weight: bold;
}
.levier_subtitle {
  margin-bottom: 50px;
}
.levier_text {
  text-align: center;
  padding: 0 20px;
  margin-top: 30px;
  /* margin-bottom: 50px; */
  font-size: 16px;
}
.levier_text b {
  color: #16c9d3;
}
@media (max-width: 767px) {
  .about_text {
    padding: 0 20px;
    font-size: 18px;
  }
  .synergie_logo {
    max-width: 250px;
    width: 80%;
    margin-top: 40px;
  }
}
@media (max-width: 460px) {
  .synergie_logo {
    max-width: 200px;
  }
  .about_subtitle {
    font-size: 20px;
  }
  .about_text {
    font-size: 16px;
    padding: 0 15px;
  }
}
@media (max-width: 360px) {
  .synergie_logo {
    max-width: 180px;
  }
  .about_subtitle {
    font-size: 18px;
  }
  .about_text {
    font-size: 14px;
    padding: 0 10px;
  }
}
